import * as React from "react"
import { StaticQuery, Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
export default function OnlinebarList({ en }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpOnlinebar(limit: 6, sort: { fields: [date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY. MM. DD")
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }          
              }
            }
          }
        }
      `}    
      render={(data) => {
        return (
          <section id="top-online-bar">
            <div className="inner">
              <h2><StaticImage src="../../images/ttl-onlinebar@2x.png" alt="広島オンライン酒場" /></h2>
              <h3>{ !en ? "広島オンライン酒場TANOSHIGE" : "Hiroshima Online Bar TANOSHIGE" }</h3>
              <h4>{ !en ? "毎月第2・4金曜日 20:00 OPEN" : "OPEN 8PM on the 2nd & 4th Friday of every month" }</h4>
              <p>
                { !en ? "広島の話題の人や、旬な情報満載の参加型コンテンツ。" : "The talk of the town and seasonal informations in Hiroshima are fully loaded participatory contents." }
                <br />
                { !en ? "ここは、今の広島の「食べてみたい」「行ってみたい」「教えたい」を楽しげに語り合うオンライン酒場です。" : "This is the online bar talking delightfully about the latest Hiroshima’s “wanna eat” “wanna say” “wanna tell” kind of hot topics." }
              </p>
            </div>
            <div className="inner online-bar-content">
              {data.allWpOnlinebar.edges.map(({ node }) => {
                const image = getImage(node.featuredImage?.node?.localFile)                  

                return (
                  <Link to={node.uri}><GatsbyImage image={image} alt="{node.title}" /></Link>
                )
              })}
            </div>
            <Link className="link-btn" to="/onlinebar"><span>{ !en ? "広島グルメ＆スポット一覧はこちら" : "Click Here For More" }</span></Link>
          </section>
        )
      }}
    />
  )
}