import * as React from "react"
import { StaticQuery, Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
export default function NewsList({ en }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpNews(limit: 6, sort: { fields: [date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY. MM. DD")
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }          
              }
            }
          }
        }
      `}    
      render={(data) => {
        return (
          <section id="top-news">
            <h2><StaticImage src="../../images/ttl-news@2x.png" alt="NEWS" /></h2>
            <div className="inner news-article">
                {data.allWpNews.edges.map(({ node }) => {
                  const image = getImage(node.featuredImage.node.localFile)

                  return (
                    <figure>
                      <Link to={node.uri}>
                        <GatsbyImage image={image} alt={node.title} />
                        <figcaption><span>{node.date}</span>{node.title}</figcaption>
                      </Link>
                    </figure>
                  )
                })}
            </div>
            <Link className="link-btn" to="/news"><span>{ !en ? "広島グルメ＆スポット一覧はこちら" : "Click Here For More" }</span></Link>
          </section>
        )
      }}
    />
  )
}