import * as React from "react"
import { useState } from "react"
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SwiperCore, { Thumbs, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';

SwiperCore.use([Thumbs, Autoplay, A11y]);

export default function MainSlideImageSwiperMock() {
  const [mainThumbSwiper, setMainThumbSwiper] = useState(null);

  const mainSwiperChange = (swiper) => {
    // let mainThumbSwiper = document.querySelector('#mainThumbSwiper .swiper-container').swiper

    // if (mainThumbSwiper) {
    //   mainThumbSwiper.slideToLoop(swiper.realIndex)
    // }
  }

  return (
    <StaticQuery
      query={graphql`
        query MainSlideImageSwiperQuery {
          allWpMainSlideImage(limit: 10, sort: { fields: [date], order: DESC }) {            
            edges {
              node {
                id
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }
                date(formatString: "Do MMMM")
              }
            }
          }
        }
      `}    
      render={(data) => {
        return (
          <>
            <div id="mainSwiper" className="slide-wrapper">
              <Swiper
                  autoplay={true}
                  loop={true}
                  slidesPerView={1} spaceBetween={0}
                  thumbs={{ swiper: mainThumbSwiper }}
                  onSlideChange={mainSwiperChange}
                >
                {data.allWpMainSlideImage.edges.map(({ node }) => {
                  const image = getImage(node.featuredImage.node.localFile)

                  return (
                    <SwiperSlide key={node.id}>
                      <GatsbyImage image={image} alt={node.title} />   
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div id="mainThumbSwiper" className="slide-wrapper">
              <Swiper
                autoplay={false}
                slidesPerView={4} spaceBetween={10}
                freeMode={true}
                slideToClickedSlide={true}
                onSwiper={setMainThumbSwiper}
              >
                {data.allWpMainSlideImage.edges.map(({ node }) => {
                  const image = getImage(node.featuredImage.node.localFile)

                  return (
                    <SwiperSlide key={node.id}>
                      <GatsbyImage image={image} alt={node.title} />   
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </>
        )
      }}
    />
  )
}