import * as React from "react"
import { Link, StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash'

import 'swiper/swiper.scss';
import "../../css/swiper.css"
import SwiperCore, { Pagination, Autoplay, A11y } from 'swiper'

SwiperCore.use([Pagination, Autoplay, A11y])

export default function MainSlideImageSwiper() {
  const movePagination = () => {
    let pagination = document.getElementById("topics").querySelector('.swiper-pagination')
    document.getElementById("topics").append(pagination)
  }

  return (
    <StaticQuery
      query={graphql`
        query NewPostsSwiperQuery {
          allWpNews(limit: 6, filter: {newsShowMain: {eq: true}}) {
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY.MM.DD HH:mm:ss")
                nodeType
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          allWpSightseeing(limit: 6, filter: {sightseeingShowMain: {eq: true}}) {
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY.MM.DD HH:mm:ss")
                nodeType
                tanoshigeExternalUrl
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          allWpOnlinebar(limit: 6, filter: {onlinebarShowMain: {eq: true}}) {
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY.MM.DD HH:mm:ss")
                nodeType
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}    
      render={(data) => {
        let posts = _.concat(data.allWpNews.edges, data.allWpSightseeing.edges, data.allWpOnlinebar.edges)
        
        posts = _.orderBy(posts, [(o) => { return o.node.date }], ['desc'])
        posts = _.slice(posts, 0, 6)

        if (posts.length) {
          return (
            <div id="topics">
              <Swiper
                autoplay
                loop={true}
                slidesPerView={1}
                onSwiper={movePagination}
                pagination={true}
                className="NewPostsSwiper"
              >
                {posts.map(({ node }) => {
                  let image = ''

                  if (node.featuredImage) {
                    image = node.featuredImage ? getImage(node.featuredImage.node.localFile) : ''
                  }
 
                  if (image) {
                    const content = <>
                      <figure>
                        <GatsbyImage
                          className="topics-img"
                          image={image}
                          alt={node.title}
                        />
                        <figcaption>
                          <span>{node.date.substr(0,10)}</span>
                          {node.title}
                        </figcaption>
                      </figure>
                    </>
                    if (node.nodeType === 'Sightseeing' && node.tanoshigeExternalUrl && node.tanoshigeExternalUrl !== '""') {
                      return (
                        <SwiperSlide key="{node.id}">
                          <a href={node.tanoshigeExternalUrl} target="_blank" rel="noreferrer">
                            {content}
                          </a>
                        </SwiperSlide>
                      )
                    } else {
                      return (
                        <SwiperSlide key="{node.id}">
                          <Link to={node.uri}>
                            {content}
                          </Link>
                        </SwiperSlide>
                      ) 
                    }
                  } else {
                    return
                  }
                })}
              </Swiper>
            </div>
          )
        } else {
          return
        }
      }}
    />
  )
}
