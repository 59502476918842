import * as React from "react"
import { StaticQuery, Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
export default function SightseeingList({ en }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpSightseeing(limit: 6, sort: { fields: [date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                uri
                title
                date(formatString: "YYYY. MM. DD")
                tanoshigeExternalUrl
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO]
                        )
                      }
                    }
                  }
                }          
              }
            }
          }
        }
      `}    
      render={(data) => {
        return (
          <section id="gourmet">
            <h2><StaticImage src="../../images/ttl-gourmet-spots@2x.png" alt="グルメ＆スポット" /></h2>
            <div className="inner gourmet-content">
                {data.allWpSightseeing.edges.map(({ node }) => {
                  const image = getImage(node.featuredImage.node.localFile)                  
                  const content = <>
                    <GatsbyImage image={image} alt={node.title} />
                    <figcaption><span>{node.date}</span><br />{node.title}</figcaption>
                  </>

                  if (!node.tanoshigeExternalUrl || node.tanoshigeExternalUrl === '""') {
                    return (
                      <figure>
                        <Link to={node.uri}>
                          {content}
                        </Link>
                      </figure>
                    )
                  } else {
                    return (
                      <figure>
                        <a href={node.tanoshigeExternalUrl} target="_blank" rel="noreferrer">
                          {content}
                        </a>
                      </figure>
                    ) 
                  }
                })}
            </div>
            <Link className="link-btn" to="/sightseeing"><span>{ !en ? "広島グルメ＆スポット一覧はこちら" : "Click Here For More" }</span></Link>
          </section>
        )
      }}
    />
  )
}